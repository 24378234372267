import $ from "jquery";
import 'bootstrap';
import dtbs from "datatables.net-bs5";
import dtSl from './data-tables-sl.json';
import * as toasts from "./toasts.js";
import float from "js-float-calculation";
import AirDatepicker from 'air-datepicker';
import localeSl from './sl';

import * as fnc from "../js/modules/functions.js";

const beforeUnloadListener = (event) => {
	event.preventDefault();
	return event.returnValue = "Ali ste prepričani, da želite končati urejanje?";
};

var unloadDefined = false;
function definePageUnload(){
	$(' #vloga input, #vloga textarea, #vloga select ').on('change', (event) => {
		if(!unloadDefined){
			addEventListener("beforeunload", beforeUnloadListener, {capture: true});
			unloadDefined = true;
		}
	});
}

function sumChanged(){
	$('input.sum').each(function(){
		
		var fields = this.dataset.fields.split(',');
		var avg = $(this).hasClass('avg');
		var cntFields = 0;
		let sumVal = parseFloat('0.00');
		fields.forEach(field => {
			if($('#'+field).length){
				cntFields++;
				let fieldValue = parseNumber($('#'+field))
				sumVal = sumVal.add(fieldValue); // because of float values, you cant simply +
			}
		});
		
		if(sumVal >= 0 && !isNaN(sumVal)){
			
			if(avg){
				sumVal = sumVal/cntFields;
			}
			printNumber($(this), sumVal, true);
			
			if($(this).data('type') == 'percent' && sumVal > 100){
				$(this).addClass('is-invalid');
			} else {
				$(this).removeClass('is-invalid');
			}
		}
	});
}

function sumContainChanged(){
	$('input.sumcontains').each(function(){
		var containString = $(this).data('contains');
		let sumVal = parseFloat('0.00');
		var avg = $(this).hasClass('avg');
		var cntFields = 0;
		$('input[id^="'+containString+'"]').each(function(){
			let field = $(this).attr('id');
			if($('#'+field).length){
				let fieldValue = parseNumber($('#'+field));
				if(fieldValue>0){
					cntFields++;
				}
				sumVal = sumVal.add(fieldValue);
			}
		});
		if(sumVal >= 0 && !isNaN(sumVal)){
			if(avg && cntFields > 0){
				sumVal = sumVal/cntFields;
			}
			printNumber($(this), sumVal, true);
			
			if($(this).data('type') == 'percent' && sumVal > 100){
				$(this).addClass('is-invalid');
			} else {
				$(this).removeClass('is-invalid');
			}
		}
	})
}

function validateRestrictions(valid = true){
	const elements = document.querySelectorAll(`[data-percent], [data-equal], [data-max], [data-maxpre]`);
	elements.forEach(function(element){
		
		let text = '';
		let validated = true;
		const inputedValue = parseNumber($(element));
		
		if(element.hasAttribute('data-percent')){
			const percent = parseInt(element.dataset.percent);
			let sumValue = 0;
			if(detectFieldId(`#${element.dataset.sum}`)){
				sumValue = parseNumber($(`#${element.dataset.sum}`));
			}
			
			if( inputedValue && sumValue ){
				const calculatedValue = percent/100 * sumValue;
				if(calculatedValue < inputedValue){
					validated = false;
					const textToShow = element.dataset.textequal ?? `Znesek ne sme preseči ${percent} % skupne vrednosti programa/projekta.`;
					text = appendTextWithBR(text, textToShow);
				}
			}
		}
		if(element.hasAttribute('data-max') || element.hasAttribute('data-maxpre')){
			var max = 0;
			if(element.hasAttribute('data-max')){
				max = parseInt(element.dataset.max);
			}else if(element.hasAttribute('data-maxpre')){
				max = parseInt(element.dataset.maxpre);
			}
			
			const percent = parseInt(element.dataset.percentmax);
			
			let sumValue = 0;
			if(detectFieldId(`#${ element.dataset.sum}`)){
				sumValue = parseNumber($(`#${ element.dataset.sum}`));
			}
			
			if( inputedValue  && sumValue ){
				const calculatedValue = percent/100 * sumValue;
				
				if( calculatedValue < inputedValue || inputedValue > max ){
					validated = false;
					if(element.hasAttribute('data-max')){
						const textToShow = element.dataset.textequal ?? `Znesek ne sme preseči ${percent} % skupne vrednosti programa/projekta in ${fnc.numberWithCommas(max)} EUR razpisanih sredstev.`;
						text = appendTextWithBR(text, textToShow);
						
					} else if(element.hasAttribute('data-maxpre')){
						const textToShow = element.dataset.textequal ?? `Znesek ne sme preseči ${percent} % upravičenih stroškov predlaganih posegov in ${fnc.numberWithCommas(max)} EUR razpisanih sredstev.`;
						text = appendTextWithBR(text, textToShow);
					}
				}
			}
		}
		if(element.hasAttribute('data-equal')){
			let equalValue = 0;
			const selectors = element.dataset.equal.split(",");
			for( let selectorId of selectors){
				selectorId = selectorId.trim();
				if(selectorId == ''){
					break;
				}
				if(detectFieldId(`#${selectorId}`)){
					equalValue = parseNumber($(`#${selectorId}`));
				}
				
				if( inputedValue && equalValue ){
					if( inputedValue !== equalValue ){
						validated = false;
						const textToShow = element.dataset.textequal ?? `Zneska prihodkov in odhodkov se morata ujemati.`;
						text = appendTextWithBR(text, textToShow);
						break;
					}
				}
			}
		}
		
		$(element).removeClass('is-invalid');
		$(element).siblings(`.invalid-feedback`).remove();
		
		if(!validated){
			valid = false;
			$(element).addClass('is-invalid');
			$(element).after(`<div class="invalid-feedback">${text}</div>`);
		}
		
	});
	return valid;
}

function detectFieldId(selector){
	if($(selector).length == 0){
		toasts.show({
			message:'NAPAKA pri primerjanju vrednosti polj - ID polja ne obstaja!',
			color: 'danger'
		});
		return false;
	}
	return true;
}
function appendTextWithBR(text, appendString){
	if(text != ''){
		text += '<br/>'
	}
	text += appendString;
	
	return text;
}


function triggerFieldChangeMultiplyContains(){
	$('input.multiplay').each(function(){
		
		let field1 = $(this).data('field1');
		let field1Value = parseNumber($('#'+field1));
		let field2 = $(this).data('field2');
		let field2Value = parseNumber($('#'+field2));
		
		let multiplyValue = 0.00;
		multiplyValue = field1Value * field2Value;
		
		if(!isNaN(multiplyValue)){
			printNumber($(this), multiplyValue, true);
			sumChanged();
			sumContainChanged();
		}
	})
}
function triggerNumChange(){
	$(document).on('change', 'input.number:not(.nodecimal)', function(){
		
		triggerFieldChangeMultiplyContains();
		sumContainChanged();
		sumChanged();
		
		validateRestrictions();
		
		printNumber($(this), parseNumber($(this)), true);
	});
}

function parseNumber(element){
	let value = String($(element).val());
	if(value !== ''){
		if($(element).hasClass('whole')){
			value = value.replace(/[^0-9]*/g, ''); // remove all text, leave only numbers
			value = parseInt(value);
		}else{
			value = value.replace(/[^0-9,]*/g, ''); // remove all text, leave only numbers and commas
			value = value.replaceAll(',','.');
			value = parseFloat(value);
		}
	}
	if(isNaN(value)){
		value = 0;
	}
	return value;
}

function printNumber(element, value, printZero = false){
	if(value !== ''){
		if(value !== 0){
			if($(element).hasClass('whole')){
				$(element).val(parseInt(value));
			}else{
				$(element).val(fnc.numberWithCommas(parseFloat(value)));
			}
		}else if(printZero && value === 0){
			if($(element).hasClass('whole')){
				$(element).val('0');
			}else{
				$(element).val('0,00');
			}
		}
	}
}

function detectRequiredDrugoChange(){
	detectRequiredDrugo();
	$(document).on('change', '[id^="status_izvajalca"]', function(){
		detectRequiredDrugo();
	});
}

function detectRequiredDrugo(valid = true){
	const selectField = document.querySelector(`[id^="status_izvajalca"]`);
	const drugiStatus = document.querySelector(`[id^="drugi_status"]`);
	if(selectField){
		if(selectField.value == 'drugo'){
			drugiStatus.required = true;
			if(drugiStatus.value == ''){
				$(drugiStatus).addClass('is-invalid');
				var text = `Če ste izbrali status izvajalca "drugo", je to polje obvezno!`
				$(drugiStatus).after(`<div class="invalid-feedback">${text}</div>`);
				
				return false;
			}else{
				$(drugiStatus).removeClass('is-invalid');
				$(drugiStatus).siblings(`.invalid-feedback`).remove();
			}
		}else{
			$(drugiStatus).removeClass('is-invalid');
			$(drugiStatus).siblings(`.invalid-feedback`).remove();
		}
	}
	return valid;
}


var podrocjeDataId = null;
var podrocjeTarget = null;
const mutuallyExclusive = ["prireditev_vecja", "prireditev_mnozicna", "prireditev_mednarodna"];

function displayPodrocja(){
	if(document.querySelector('input[name^="podrocje"]')){
		
		mutuallyExclusive.forEach(function(checkboxId){
			const checkboxElement = document.getElementById(checkboxId);
			if(checkboxElement && checkboxElement.checked){
				mutuallyExclusive.forEach(function(disableId){
					if(disableId != checkboxId){
						$(`label[for="${disableId}"]`).addClass('disabled');
						$(`label[for="${disableId}"]`).attr('disabled', '');
						$(`#${disableId}`).attr('disabled', '');
					}
				});
			}
		});
		
		$('input[name^="podrocje"]').on('change', function(e){
			e.preventDefault();
			e.stopPropagation();
			
			if(e.target.checked){
				
				var vlogaId = $('#vloga').data('id');
				var type = $(this).data('id');
				var podrocjeWrapper = $('.podrocje_wrapper[data-type="'+type+'"]');
				
				var canCheck = true;
				if( mutuallyExclusive.includes(type) ){
					mutuallyExclusive.forEach(function(checkboxId){
						if(checkboxId != type && document.getElementById(checkboxId).checked){
							canCheck = false;
						}
					});
					if(canCheck){
						mutuallyExclusive.forEach(function(checkboxId){
							if(checkboxId != type){
								$(`label[for="${checkboxId}"]`).addClass('disabled');
								$(`label[for="${checkboxId}"]`).attr('disabled', '');
								$(`#${checkboxId}`).attr('disabled', '');
							}
						});
					}
				}
				
				/*
				var key = 0; // enable key for dulicated podrocje
				$('.wrap_finance[data-type="'+type+'"]').each(function(e){
					key++;
				});
				*/
				
				if(canCheck){
					$.get('/evloge/vloga/'+vlogaId+'/'+type+'', {/*'key':key,*/ 'showsection':true}, function(data){
						if(data != 'error'){
							//podrocjeWrapper.append(data);
							podrocjeWrapper.html(data);
							triggerFieldChangeMultiplyContains();
						}
					});
					//$('.dokazila_wrapper[data-type="'+type+'"]').addClass('show');
					
				}else{
					e.target.checked = false;
				}
				
			}else{
				/*if(confirm('Želite odstraniti celotno področje? \nPo tem dejanju ni mogoče več povrniti izbrisanih podatkov!')){
					
					$('.podrocje_wrapper[data-type="'+$(this).data('id')+'"] .insideWrap').remove();
					triggerFieldChangeMultiplyContains();
					
				} else {
					e.target.checked = true;
				}*/
				
				e.target.checked = true;
				const siblingText = $($(this).parent().siblings()[0]).text();
				$(`#removePodrocjeLabel span`).text(siblingText);
				$(`#removePodrocje`).modal('show');
				
				podrocjeDataId = $(this).data('id');
				podrocjeTarget = e.target;
				
				modalConfirm(function(confirm){
					if(confirm){
						podrocjeTarget.checked = false;
						$('.podrocje_wrapper[data-type="'+podrocjeDataId+'"] .insideWrap').remove();
						
						mutuallyExclusive.forEach(function(checkboxId){
							$(`label[for="${checkboxId}"]`).removeClass('disabled');
							$(`label[for="${checkboxId}"]`).prop("disabled", false);
							$(`#${checkboxId}`).prop("disabled", false);
							
						});
						
					}
					$("#removePodrocje [data-bs-dismiss=modal]").trigger('click');
				});
			}
		});
		
		var modalConfirm = function(callback){
			$("#removePodrocjeBtn").on("click", function(){
				callback(true);
			});
		};
	}
}

function displayProjekt(){
	const allSelects = document.querySelectorAll('select[id^=stevilo_projektov]');
	if(allSelects.length > 0){
		for( var i = 0; i < allSelects.length; i++ ){
			
			let prevVal = parseInt(allSelects[i].value);
			const parent = allSelects[i].closest('.numberProjects');
			const leto = parent.dataset.leto;
			
			$(allSelects[i]).on('change', function(e){
				e.preventDefault();
				e.stopPropagation();
				
				const newVal = parseInt(this.value);
				const difference = newVal - prevVal;
				
				const rootId = 'projektWrapper';
				if(difference != 0){
					if(difference > 0){ // dodal
						var vlogaId = $('#vloga').data('id');
						
						let selector = `#${rootId}_${newVal}`;
						let params = { 'key': newVal };
						
						const minKey = parseInt(prevVal) + 1;
						const maxKey = newVal;
						
						for(var key = minKey; key <= maxKey; key++ ){
							
							if(leto){
								params['leto'] = leto;
								selector = `#${rootId}_${leto}_${newVal}`;
							}
							
							const wrapper = document.querySelector(selector);
							
							const template = wrapper.dataset.template;
							params['template'] = template;
							
							$.get('/evloge/vloga/'+vlogaId+'/addprojekt/', params, function(data){
								if(data != 'error'){
									wrapper.innerHTML = data;
								}
							});
						}
						
						prevVal = newVal;
					} else { // odvzel
						$(`#remProject`).modal('show');
						modalConfirm(function(confirm){
							if(confirm){
								const maxKey = parseInt(prevVal);
								const minKey = newVal;
								
								for(var key = maxKey; key > minKey; key-- ){
									let selector = `#${rootId}_${key}`;
									if(leto){
										selector = `#${rootId}_${leto}_${key}`;
									}
									document.querySelector(selector).innerHTML = '';
								}
								prevVal = newVal;
							}
							$("#remProject [data-bs-dismiss=modal]").trigger('click');
						});
						modalCancel(function(cancel){
							if(cancel){
								document.querySelector(selector).value = prevVal;
							}
							$("#remProject [data-bs-dismiss=modal]").trigger('click');
						});
					}
				}
			});
			var modalCancel = function(callback){
				$("#cancelRemoveProjectBtn").on("click", function(){
					callback(true);
				});
			};
			var modalConfirm = function(callback){
				$("#removeProjectBtn").on("click", function(){
					callback(true);
				});
			};
		}
	}
}

var sklopDataId = null;
var sklopTarget = null;
function displaySklop(){
	if(document.querySelector('input[name^="sklop"]')){
		
		$('input[name^="sklop"]').on('change', function(e){
			e.preventDefault();
			e.stopPropagation();
			
			if(e.target.checked){
				var vlogaId = $('#vloga').data('id');
				const parentCheckboxes = this.closest('.section.checkmarks');
				const template = parentCheckboxes.dataset.template;
				let key = parentCheckboxes.dataset.key;
				key = !key ? 0 : key++;
				parentCheckboxes.dataset.key = key;
				
				sklopDataId = this.dataset.id;
				const title = this.closest('.inputGroup').getElementsByTagName('p')[0].innerHTML; // get title of checkbox field
				
				const leto = parentCheckboxes.dataset.leto;
				
				$.get('/evloge/vloga/'+vlogaId+'/addprojekt/', { 'key': sklopDataId, 'template': template, 'sklop': sklopDataId, 'title':title, 'leto':leto }, function(data){
					if(data != 'error'){
						document.querySelector(`.sklop_wrapper[data-type="${sklopDataId}"]`).innerHTML = data;
					}
				});
				
			}else{
				e.target.checked = true;
				const siblingText = $($(this).parent().siblings()[0]).text();
				$(`#removePodrocjeLabel span`).text(siblingText);
				$(`#removePodrocje`).modal('show');
				
				sklopDataId = $(this).data('id');
				sklopTarget = e.target;
				
				modalConfirm(function(confirm){
					if(confirm){
						sklopTarget.checked = false;
						document.querySelector(`.sklop_wrapper[data-type="${sklopDataId}"]`).innerHTML = '';
					}
					$("#removePodrocje [data-bs-dismiss=modal]").trigger('click');
				});
			}
		});
		
		var modalConfirm = function(callback){
			$("#removePodrocjeBtn").on("click", function(){
				callback(true);
			});
		};
	}
}

function addRow() {
	$(document).on('click', '.addRow', function(e){
		e.preventDefault();
		e.stopPropagation();
		this.blur();
		
		const tableroot = this.dataset.tableroot;
		const tableRow = this.dataset.tablerow;
		var interator = parseInt(this.dataset.interator) + 1;
		this.dataset.interator = interator;
		
		const table = document.querySelector(`table#${tableroot} tbody`);
		const childSumRow = table.querySelector(`.sumRow`);
		const noBottom = table.querySelectorAll(`.between.${tableRow}`);
		
		var newRow = document.querySelector(`table#${tableroot} tr.${tableRow}`).cloneNode(true);
		
		var newRowHtml = newRow.outerHTML;
		newRowHtml = newRowHtml.replaceAll(`_0_`, `_${interator}_`);
		newRowHtml = newRowHtml.replaceAll(/value="([^"]*)"/gim, ``); // Clear input values in the cloned row
		newRowHtml = newRowHtml.replaceAll(/selected="([^"]*)"/gim, ``);
		
		newRowHtml = newRowHtml.replaceAll(`<td class="counter">1`, `<td class="counter">${interator + 1}`);
		
		var wrapperTable = document.createElement('table');
		wrapperTable.innerHTML = newRowHtml;
		var preparedRow = wrapperTable.firstChild.firstChild;
		
		if (noBottom && noBottom.length > 0 ) { // Append the cloned row to the table before sumRow row
			const lastNoBottom = noBottom[noBottom.length-1];
			appendRow(preparedRow, lastNoBottom, childSumRow, false);
			
		} else if (childSumRow !== null ) { // Append the cloned row to the table before sumRow row
			appendRow(preparedRow, childSumRow);
			
		}else{ // Append the cloned row to the table
			table.appendChild(preparedRow);
		}
		
		// vsakemu polju dodaj required - če si dodal vrstico more bit use required (razen sum in multiply)
		const inputs = newRow.querySelectorAll('input:not(.multiplay):not(.sum), select');
		inputs.forEach(function (elem, i) {
			if (elem.id) {
				let elemId = elem.id;
				elemId = elemId.replace(`_0_`, `_${interator}_`);
				document.getElementById(elemId).required = true;
				
				if(elem.classList.contains('datepickeronlydate')){
					// add date picker to field with class
					setDatePicker(interator, document.getElementById(elemId));
				}
			}
		});
		
		var removeRowBtn = this.nextElementSibling;
		if (removeRowBtn) {
			removeRowBtn.classList.remove('hidden');
		}
		
		function appendRow(preparedRow, beforeRow, sumRow = null, before = true){
			table.insertBefore(preparedRow, (before ? beforeRow : beforeRow.nextSibling));
			
			const allCols = preparedRow.querySelectorAll('td');
			allCols.forEach(function (td, i) {
				const input = td.querySelector('input');
				if(input && input.classList.contains('number')){
					
					sumRow = sumRow == null ? beforeRow : sumRow;
					const currentCol = sumRow.querySelectorAll('td')[i];
					if(currentCol){
						const inputSum = currentCol.querySelector('input.sum');
						if(inputSum){
							inputSum.dataset.fields += `,${input.id}`;
						}
					}
				}
			});
		}
		
	});
	
	$(".modal").on("shown.bs.modal", function () {
		var buttonClasses = ['.removeRow', '.deleteVloga', '#removePodrocjeBtn'];
		for (const i in buttonClasses) {
			var item = document.querySelector(`#${this.id} ${buttonClasses[i]}`);
			if(item){
				item.focus();
				break;
			}
		}
	});
	$(document).on('click', '.removeRow', function(e){
		e.preventDefault();
		e.stopPropagation();
		this.blur();
		
		const tableroot = this.dataset.tableroot;
		const tableRow = this.dataset.tablerow;
		const selector = `table#${tableroot} tr.${tableRow}`;
		
		const table = document.querySelector(`table#${tableroot} tbody`);
		const childSumRow = table.querySelector('.sumRow');
		
		const numberOfRows = document.querySelectorAll(selector).length;
		if( numberOfRows <= 2 ){
			//this.classList.add('hidden');
			document.querySelector(`[data-bs-target="#remRow${tableRow}"]`).classList.add('hidden');
		}
		
		if ( numberOfRows > 1 ) {
			
			const addRowButton = document.querySelector(`.addRow[data-tablerow="${tableRow}"]`)
			addRowButton.dataset.interator -= 1;
			
			const allRows = document.querySelectorAll(`${selector}`);
			const rowToRemove = allRows[allRows.length - 1];
			
			if (childSumRow !== null ) {
				
				const allCols = rowToRemove.querySelectorAll('td');
				allCols.forEach(function (td, i) {
					const input = td.querySelector('input');
					if(input && input.classList.contains('number')){
						const currentCol = childSumRow.querySelectorAll('td')[i];
						if(currentCol){
							const inputSum = currentCol.querySelector('input.sum');
							if(inputSum){
								inputSum.dataset.fields = inputSum.dataset.fields.replace(`,${input.id}`, '');
								
							}
						}
					}
				});
				
				sumChanged();
				validateRestrictions();
			}
			
			$(`#remRow${tableRow} [data-bs-dismiss=modal]`).trigger('click');
			rowToRemove.remove();
			
		}
	});
}

function addSection() {
	$(document).on('click', '.addSection', function(e){
		e.preventDefault();
		e.stopPropagation();
		this.blur();
		
		const sectionRow = this.dataset.sectionrow;
		var interator = parseInt(this.dataset.interator) + 1;
		this.dataset.interator = interator;
		
		const section = document.querySelector( `.section#${sectionRow}` );
		
		const newId = sectionRow.replace(`_s0`, `_s${interator}`); // spremeni stevec sekcij
		
		var newSection = section.cloneNode(true);
		
		var newSectionHtml = newSection.outerHTML;
		newSectionHtml = newSectionHtml.replaceAll(sectionRow, newId); // replace section ID
		newSectionHtml = newSectionHtml.replaceAll(`_s0_`, `_s${interator}_`); // spremeni stevec polja
		newSectionHtml = newSectionHtml.replaceAll(/value="([^"]*)"/gim, ``); // Clear input values in the cloned row
		newSectionHtml = newSectionHtml.replaceAll(/selected="([^"]*)"/gim, ``);
		
		var wrapperDiv = document.createElement('div');
		wrapperDiv.innerHTML = newSectionHtml;
		var preparedRow = wrapperDiv.firstChild;
		
		
		const lastSectionRow = sectionRow.replace(`_s0`, `_s${interator-1}`);
		const lastSection = document.querySelector( `.section#${lastSectionRow}` );
		$(preparedRow).insertAfter(lastSection);
		
		var removeRowBtn = this.nextElementSibling;
		if (removeRowBtn) {
			removeRowBtn.classList.remove('hidden');
		}
		
	});
	
	$(document).on('click', '.removeSection', function(e){
		e.preventDefault();
		e.stopPropagation();
		this.blur();
		
		const sectionClass = this.dataset.sectionclass;
		const sectionRow = this.dataset.sectionrow;
		
		const numberOfRows = document.querySelectorAll(`.section.${sectionClass}`).length;
		if( numberOfRows <= 2 ){
			//this.classList.add('hidden');
			document.querySelector(`[data-bs-target="#remSec${sectionRow}"]`).classList.add('hidden');
		}
		
		if ( numberOfRows > 1 ) {
			
			const addSectionButton = document.querySelector(`.addSection[data-sectionrow="${sectionRow}"]`)
			addSectionButton.dataset.interator -= 1;
			
			const allSections = document.querySelectorAll(`.section.${sectionClass}`);
			const sectionToRemove = allSections[allSections.length - 1];
			
			$(`#remSec${sectionRow} [data-bs-dismiss=modal]`).trigger('click');
			sectionToRemove.remove();
		}
	});
}

function addProject() {
	$(document).on('click', '.addProject', function(e){
		e.preventDefault();
		e.stopPropagation();
		this.blur();

		 $("#includedContent").load("vloge/mladina_includes/new_projekt.html.twig");

		var removeRowBtn = this.nextElementSibling;
		if (removeRowBtn) {
			removeRowBtn.classList.remove('hidden');
		}
		
	});
	
}

function save(){
	if ($('form#vloga').hasClass("saveOsnutekVloga")) {
		var disabled = $('#vloga input:disabled').removeAttr('disabled');
		const formData = fnc.getFormData($('#vloga'));
		
		if(!fnc.allKeysEmpty(formData)){
			var formDataJson = JSON.stringify(formData);
			disabled.attr('disabled','disabled');
			let recordID = $('#vloga').data('dataid');
			let userId = $('#vloga').data('userid');
			
			$.post('/evloge/vloga/'+recordID, { 'data':formDataJson, 'userid':userId }, function(data){
				if(fnc.isJsonString(data)){
					var jsonData = JSON.parse(data);
					if(jsonData.error == false){
						toasts.show({
							message:'Vloga je bila shranjena kot osnutek.'
						});
						if(unloadDefined){
							removeEventListener("beforeunload", beforeUnloadListener, {capture: true});
						}
						$(document).trigger('saved');
					}else{
						if(jsonData.useriderror){
							toasts.show({
								message:`NAPAKA<br>
											Prijavljen uporabnik se ne sklada z vlogo!<br>
											Zaradi varnosti podatkov vloga ni bila shranjena!<br><br>
											Za odpravo napake se morate odjaviti in ponovno prijaviti.<br>
											Morebitne spremembe niso bile shranjene, zato si jih ročno shranite na vaš računalnik v beležnico!`,
								color: 'danger',
								time: 10000000
							});
						} else {
							toasts.show({
								message:'Napaka, vloga ni bila shranjena kot osnutek.',
								color: 'danger'
							});
						}
					}
				}else{
					console.log(data);
					toasts.show({
						message:'Napaka, vloga ni bila shranjena kot osnutek.',
						color: 'danger'
					});
				}
			})
		}
	}
}

function saveClickTrigger(){
	if($('#save').length){
		$('#save').on('click', function(e){
			e.preventDefault();
			e.stopPropagation();
			save();
		})
	}
}

function deleteClickTrigger(){
	if($('.deleteVloga').length){
		$('.deleteVloga').on('click', function(e){
			e.preventDefault();
			e.stopPropagation();
			let recordID = $(this).data('dataid');
			$.post('/evloge/vloga/delete/'+recordID, {	}, function(data){
				window.location.href = `/evloge`;
			})
		})
	}
}

function timeSaveTrigger(){
	if($('#vloga').length && !$('form#vloga').hasClass("versionDisplay")){
		var interVal = setInterval(function(){
			$('#save').trigger('click');
		},60000);
	}
}

function saveComments(){
	var disabled = $('#vloga input:disabled').removeAttr('disabled');
	const formData = fnc.getFormData($('#vloga'));
	
	var formDataJson = JSON.stringify(formData);
	disabled.attr('disabled','disabled');
	let recordID = $('#vloga').data('dataid');
	
	$.post(`/eva/savecomments/${recordID}`, { 'data':formDataJson }, function(data){
		var jsonData = JSON.parse(data);
		if(data.length > 5 && !jsonData.error){
			toasts.show({
				message:'Komentarji so bili shranjeni kot osnutek.'
			});
		}else{
			toasts.show({
				message:'Pri shranjevanju komentarjev je prišlo do napake!',
				color: 'danger'
			});
		}
	})
	
}

function saveCommentsClickTrigger(){
	if($('#saveComments').length){
		$('#saveComments').on('click', function(e){
			e.preventDefault();
			e.stopPropagation();
			saveComments();
		})
	}
}

function timeSaveCommentsTrigger(){
	if($('#saveComments').length){
		var interValSaveComments = setInterval(function(){
			$('#saveComments').trigger('click');
		},60000);
	}
}

function supplementationApplication(){
	const selector = '#supplementation';
	if($(selector).length){
		$(selector).on('click', function(e){
			e.preventDefault();
			e.stopPropagation();
			
			var formData = fnc.getFormData($('#vloga'));
			const isEmpty = Object.values(formData).every(x => x === null || x === '');
			if(!isEmpty) { // can return if at least one new comment
				$('.loadingWrapper').addClass('opacity-loading').trigger('classChangeLoading');
				
				saveComments();
				
				let vlogaID = $('#vloga').data('id');
				let recordID = $('#vloga').data('dataid');
				
				$.post(`/eva/supplementation/${recordID}`, { }, function(data){
					let error = false;
					if(fnc.isJsonString(data)){
						var jsonData = JSON.parse(data);
						if(data.length > 5 && !jsonData.error){
							
							window.location.href = `/eva/vloga/${vlogaID}`;
							$('.loadingWrapper').removeClass('opacity-loading');
							
						} else {
							error = true;
						}
					} else {
						error = true;
					}
					if(error){
						$('.loadingWrapper').removeClass('opacity-loading');
						var text = 'Pri oddajanu vloge v dopolnjevanje je prišlo do težave!';
						if(jsonData.text){
							text = jsonData.text;
						}
						toasts.show({
							message: text,
							color: 'danger'
						});
					}
				})
			}else{
				toasts.show({
					message:'Ni komentarjev!<br>Če želite vrniti vlogo v dopolnjevanje, morate vnesti komentar, ki se nato vpiše v poziv na dopolnitve.',
					color: 'danger',
					time: 30000
				});
				$(`#modalDopolnitev [data-bs-dismiss=modal]`).trigger('click');
			}
		})
	}
}

function rejectApplication(){
	const selector = '#reject';
	if($(selector).length){
		$(selector).on('click', function(e){ // Zavrni z odločbo
			e.preventDefault();
			e.stopPropagation();
			
			var formData = fnc.getFormData($('#vloga'));
			const isEmpty = Object.values(formData).every(x => x === null || x === '');
			
			if (isEmpty) { // detect if new comments
				$('.loadingWrapper').addClass('opacity-loading').trigger('classChangeLoading');
				
				let vlogaID = $('#vloga').data('id');
				let recordID = $('#vloga').data('dataid');
				
				$.post(`/eva/reject/${recordID}`, { 'rejectType': 'odlocba' }, function(data){
					var jsonData = JSON.parse(data);
					if(data.length > 5 && !jsonData.error){
						window.location.href = `/eva/vloga/${vlogaID}`;
					}else{
						toasts.show({
							message:'Pri označevanju vloge kot zavrnjene z odločbo je prišlo do težave!',
							color: 'danger'
						});
					}
				})
			}else{
				toasts.show({
					message:'Obstajajo neoddani komentarji.<br>Vlagatelj ne bo videl teh novih komentarjev, če bo vloga zavrnjena.<br>Če želite zavrniti to vlogo, izbrišite komentarje.',
					color: 'danger',
					time: 30000
				});
				$(`#modalZavrni [data-bs-dismiss=modal]`).trigger('click');
			}
		})
	}
	
	const selector2 = '#reject2';
	if($(selector2).length){
		$(selector2).on('click', function(e){ // Zavrži s sklepom
			e.preventDefault();
			e.stopPropagation();
			
			var formData = fnc.getFormData($('#vloga'));
			const isEmpty = Object.values(formData).every(x => x === null || x === '');
			
			if (isEmpty) { // detect if new comments
				$('.loadingWrapper').addClass('opacity-loading').trigger('classChangeLoading');
				
				let vlogaID = $('#vloga').data('id');
				let recordID = $('#vloga').data('dataid');
				
				$.post(`/eva/reject/${recordID}`, { 'rejectType': 'sklep' }, function(data){
					var jsonData = JSON.parse(data);
					if(data.length > 5 && !jsonData.error){
						window.location.href = `/eva/vloga/${vlogaID}`;
					}else{
						toasts.show({
							message:'Pri označevanju vloge kot zavrnjene s sklepom je prišlo do težave!',
							color: 'danger'
						});
					}
				})
			}else{
				toasts.show({
					message:'Obstajajo neoddani komentarji.<br>Vlagatelj ne bo videl teh novih komentarjev, če bo vloga zavrnjena.<br>Če želite zavrniti to vlogo, izbrišite komentarje.',
					color: 'danger',
					time: 30000
				});
				$(`#modalZavrni [data-bs-dismiss=modal]`).trigger('click');
			}
		})
	}
}

function confirmApplication(){
	const selector = '#confirm';
	if($(selector).length){
		$(selector).on('click', function(e){
			e.preventDefault();
			e.stopPropagation();
			
			var formData = fnc.getFormData($('#vloga'));
			const isEmpty = Object.values(formData).every(x => x === null || x === '');
			
			if (isEmpty) { // detect if new comments
				$('.loadingWrapper').addClass('opacity-loading').trigger('classChangeLoading');
				
				let vlogaID = $('#vloga').data('id');
				let recordID = $('#vloga').data('dataid');
				
				$.post(`/eva/confirm/${recordID}`, { }, function(data){
					var jsonData = JSON.parse(data);
					if(data.length > 5 && !jsonData.error){
						window.location.href = `/eva/vloga/${vlogaID}`;
					}else{
						toasts.show({
							message:'Pri oddajanu vloge v točkovanje je prišlo do težave!',
							color: 'danger'
						});
					}
				})
			}else{
				toasts.show({
					message:'Obstajajo neoddani komentarji.<br>Vlagatelj ne bo videl teh novih komentarjev, če bo vloga potrjena.<br>Če želite potrditi to vlogo, izbrišite komentarje.',
					color: 'danger',
					time: 30000
				});
				
				$("#modalVTockovanje [data-bs-dismiss=modal]").trigger('click');
			}
		})
	}
}

function vrociDopolnitve(){
	const selector = '#vrociDopolnitve';
	if($(selector).length){
		$(selector).on('click', function(e){
			e.preventDefault();
			e.stopPropagation();
			
			const dateDopolnitev = $('#dateDopolnitev').val();
			if(dateDopolnitev != ''){
				$("#modalDopolnitve [data-bs-dismiss=modal]").trigger('click');
				$('.loadingWrapper').addClass('opacity-loading').trigger('classChangeLoading');
				
				let vlogaID = $('#mainList').data('id');
				$.post(`/eva/dopolnitve/${vlogaID}`, { 'date': dateDopolnitev }, function(data){
					var jsonData = JSON.parse(data);
					if(data.length > 5 && !jsonData.error){
						toasts.show({
							message:'Vloge so bile vročene v dopolnitve. Dopolnitve so odprte do: '+dateDopolnitev,
							time: 100000
						});
						window.open(`${jsonData.pathZip}?v${Date.now()}`, "_blank");
					}else{
						toasts.show({
							message: jsonData.message ? jsonData.message : 'Pri pošiljanju vlog v dopolnitve je prišlo do težave!',
							color: 'danger'
						});
					}
					$('.loadingWrapper').removeClass('opacity-loading');
					window.location.reload();
				})
			}else{
				// manjka datum!
				toasts.show({
					message: 'Vnesi datum zaprtja dopolnitev!',
					color: 'danger'
				});
			}
		})
	}
}

function validateForm(){
	$('.validateForm').on('click', function(e){
		e.preventDefault();
		e.stopPropagation();
		
		$('.is-invalid').removeClass('is-invalid');
		$('.invalid-feedback').remove();
		var valid = true;
		
		valid = validateRestrictions(valid);
		
		valid = validateClanstvo(valid);
		valid = interlockingTableInputs(valid);
		valid = detectRequiredDrugo(valid);
		
		
		$( ':input[required]', $('#vloga, #porocilo')).each(function(element){
			if(this.value.trim() == '' && $(this).parent().is(':visible') && !$(this).hasClass("is-invalid")){ // && !$(this).attr('readonly')
				valid = false;
				$(this).addClass('is-invalid');
				let char = '/';
				if($(this).hasClass('number')){
					char = '0';
				}
				$(this).after(`<div class="invalid-feedback">Tega polja niste izpolnili. Če za vas ni relevantno vnesite: ${char} </div>`);
			}
		});
		//valid = validateIzjava(valid);
		
		if(!valid){
			toasts.show({
				message:'Izpolnite vsa zahtevana polja!',
				color: 'danger',
				time: 10000
			});
			$('.is-invalid').first()[0].scrollIntoView({ behavior: "instant", block: "center", inline: "center" });
		} else {
			$('#save, #savePorocilo').trigger('click');
			const link = $(this).prop('href');
			$(document).on('saved', function(e){
				window.location.href = link;
			});
		}
	});
	$( ':input[required]', $('#vloga, #porocilo')).on('change', function(){
		if(this.value.trim() != ''){
			$(this).removeClass('is-invalid');
			$(this).closest('.invalid-feedback').remove();
		}
	});
}

function validateClanstvo(valid = true){
	if($('#vloga').length && $('.clanstvo').length){
		
		const tables = document.querySelectorAll(`table.clanstvo`);
		let sumValue = 0;
		tables.forEach(function(table){
			const tableId = table.id;
			const rows = table.querySelectorAll(`tr`);
			rows.forEach(function(row){
				if(row.classList.contains(tableId)){
					const inputs = row.querySelectorAll(`input`);
					// ce je usaj en input poln ga štej
					let full = false;
					for (const input of inputs) {
						if (input.value != '') {
							full = true;
							break;
						}
					}
					if(full){
						sumValue++;
					}
				}
			})
		});
		
		const inputField = $(`#skupaj_clani`);
		const inputedValue = parseInt(inputField.val());
		if( sumValue ){
			if( inputedValue == sumValue ){
				$(inputField).removeClass('is-invalid');
				$(`.invalid-feedback`).remove();
				
			}else if( inputedValue != 0 && inputedValue != '' ){
				if(!$(inputField).hasClass('is-invalid')){
					$(inputField).addClass('is-invalid');
					var text = `Število v tem vnosnem polju mora biti enako seštevku članov po kategorijah. (Število vnesenih članov po kategorijah: ${sumValue} )`
					$(inputField).after(`<div class="invalid-feedback">${text}</div>`);
				}
				valid = false;
			}
		}
	}
	return valid;
}

function interlockingTableInputs(valid){
	const text = '<div class="invalid-feedback">Vsa vnosna polja v eni vrstici morajo biti izpolnjena!</div>';
	const rows = document.querySelectorAll(".interlocking");
	for ( let i = 0; i < rows.length; i++ ) { // gremo po vseh vrsticah
		const inputs = rows[i].querySelectorAll("input:not(.sum):not(.multiplay), select");
		var rowHas = false;
		for ( let j = 0; j < inputs.length; j++ ) { // gremo po vseh inputih, pogledamo če je vsaj eden izpolnjen
			if( inputs[j].value != '' ){
				rowHas = true;
				break;
			}
		}
		if(rowHas){
			for ( let j = 0; j < inputs.length; j++ ) { // gremo po vseh inputih, pogledamo kateri so prazni in njim damo opozorilo
				if( inputs[j].value == '' ){
					$(inputs[j]).addClass('is-invalid');
					$(inputs[j]).after(text);
					valid = false;
				}
			}
		}
	}
	return valid;
}


// DATEPICKER

const today = new Date();
today.setHours(23);
today.setMinutes(59);

var oneYearFromNow = new Date();
oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
const settings = {
	locale: localeSl,
	startDate: today,
	maxDate: oneYearFromNow,
	dateFormat: 'd. M. yyyy',
	position: "top center",
	
	onSelect: function(data) {
		$(data.datepicker.$el).trigger('change');
	}
};
const settingsTime = {
	...settings,
	timepicker: true,
	minDate: today,
	maxDate: oneYearFromNow,
};

var datepickersList = [];
function setDatePicker( index, element, settings ) {
	
	if(element.value){
		if(element.value.includes(':')){
			function parseDateWithTime(dateString) {
				// Split the string into its components (day, month, year, hour, minute)
				const parts = dateString.split(" ");
				const [day, month, year, time] = parts;
				
				// Split the time part into hour and minute
				const [hour, minute] = time.split(":");
				
				// Create a Date object with individual components
				return new Date(year, month - 1, day, hour, minute);
			}
			settings = {
				...settings,
				selectedDates: parseDateWithTime(element.value)
			};
		}else{
			function parseDate(dateString) {
				// Split the string into its components (day, month, year, hour, minute)
				const parts = dateString.split(" ");
				const [day, month, year] = parts;
				
				// Create a Date object with individual components
				return new Date(year, month - 1, day);
			}
			settings = {
				...settings,
				selectedDates: parseDate(element.value)
			};
		}
	}
	
	datepickersList[index] = new AirDatepicker(element, settings);
	
	$(element).siblings('.icon').on( "click", function() {
		$(this).siblings('input').focus();
		datepickersList[index].show();
	});
}

function datepickers(){
	$('input.datepickeronlydate').each(function(index){
		setDatePicker(index, this, settings);
	});
	$('input.datepicker').each(function(index){
		setDatePicker(index, this, settingsTime);
	});
}

$(function(){
	
	validateRestrictions();
	
	triggerNumChange();
	
	displayPodrocja();
	displayProjekt();
	displaySklop();
	
	saveClickTrigger();
	timeSaveTrigger();
	
	definePageUnload();
	
	validateForm();
	detectRequiredDrugoChange();
	
	deleteClickTrigger();
	
	addRow();
	addSection();
	addProject();
	
	// ADMIN
	datepickers();
	
	saveCommentsClickTrigger();
	timeSaveCommentsTrigger();
	
	supplementationApplication();
	confirmApplication();
	rejectApplication();
	vrociDopolnitve();
	
	
	let settings = {
		language: dtSl,
		stateSave: true,
		paging: false,
		info: false,
		responsive: true
	}
	
	new dtbs('#submissions', {
		...settings,
		order: [],
		//order: [[1, 'ASC']],
		columnDefs: [{
			targets: -1,
			orderable: false
		},{
			targets: [0, 1], // Vlagatelj & Program
			width: '200px'
		}]
	});
	
	new dtbs('#scores', {
		...settings,
		searching: false,
		order: [[0, 'ASC']],
		columnDefs: [{
			targets: -1,
			orderable: false
		},{
			targets: [0, 1], // Vlagatelj & Program
			width: '200px'
		},{
			targets: 'inputCol',
			orderDataType: 'dom-text',
			type: 'numeric',
			width: '80px'
		},{
			targets: 'sumCol',
			width: '80px'
		}
	]
	});
	
	new dtbs('#userslist', {
		...settings,
		columnDefs: [{
			targets: 4,
			orderable: false
		}]
	});
	
	new dtbs('#sessionlist', {
		...settings,
		columnDefs: [{
			targets: -1,
			orderable: false
		}]
	});
	
	new dtbs('#logslist', {
		language: dtSl,
		info: false,
		paging: true,
		pageLength: 100,
		lengthChange: false,
		order: [[0, 'desc']],
		/* ajax: '/eva/logs/page',
		processing: true,
		serverSide: true */
	});
	
});
